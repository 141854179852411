import React, { useState } from 'react';
import './App.css';
import Login from './components/Login';
import MainPage from './components/MainPage';

function App() {
  const clientId = '771500552143-036ivbjot0ofdpvnmnl4apho015lvnvr.apps.googleusercontent.com';
  const [auth, setAuth] = useState(undefined);
  return (
    <>
      { !auth ?
        <Login clientId={clientId} setAuth={setAuth} /> 
          :
        <MainPage auth={auth} clientId={clientId} setAuth={setAuth} />
      }
    </>
  );
}

export default App;
