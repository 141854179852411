import React from 'react';

function Documentation({ article }) {
    console.log(article);
    return (
        <>
            {article === "install" &&
                <>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">How to install</h6>
                                </div>
                                <div class="card-body">
                                    <h5>Install, Java, Minecraft and Forge</h5>
                                    Doubleclick on each installer in the order below and complete the installation wizards.
                                    <ul>
                                        <li><code>1-jre-8u271-windows-x64.exe</code></li>
                                        <li><code>2-Minecraft_1.15.2_launcher.exe</code></li>
                                        <li><code>3-forge-1.16.1-32.0.108-installer.jre</code></li>
                                        <ul>
                                            <li>When starting this installer you'll get 3 options - leave it at the default "<i>Install Client</i>" option</li>
                                        </ul>
                                    </ul>
                                    <br />

                                    <h5>2. Open folder <code>%appdata%\.minecraft\</code> - copy <code>mods</code>-folder into it</h5>
                                    <li>This step is only nessesary to play the gamemodes that have mods loaded</li>
                                    <br />

                                    <h5>3. Start Minecraft launcher:</h5>
                                    <li>Click button "Add profile".</li>
                                    <li>Near the botton, in the release, dropdown scroll to the bottom and select "1.16.1-forge"</li>
                                    <li>At the top, give the profile a name like "1.16.1-forge</li>
                                    <li>Click "Save"</li>
                                    <br />
                                    <h5>4. Enter your name in the field just above the "Launch the game" button</h5>
                                    <br />
                                    <h5>5. Click "Launch the game"</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {article === "join" &&
                <>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">How to join a game</h6>
                                </div>
                                <div class="card-body">
                                    <h5>Open the game and click "Multiplayer"</h5>
                                    Use "Add server" to add:
                                    <ul>
                                        <li><code>world.protek.dk:10000</code></li>
                                        <li><code>world.protek.dk:20000</code></li>
                                        <li><code>world.protek.dk:30000</code></li>
                                        <li><code>world.protek.dk:40000</code></li>
                                    </ul>
                                    If the games are already added - just doubleclick on the one you want to join.
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {article === "pretty" &&
                <>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">How to make the game pretty</h6>
                                </div>
                                <div class="card-body">
                                    <h5>Copy the included folders:</h5>
                                    <ul>
                                        <li>resourcepacks</li>
                                        <li>shaderpacks</li>
                                    </ul>
                                    Into you <code>%appdata%\.minecraft\</code> folder<br /><br />



                                    <h5>Open the game and enable shaders</h5>
                                    <ul>
                                        <li>Go to ‘Options’ and click on the ‘Video Settings.’</li>
                                        <li>Under ‘Video Settings,’ look for ‘Shaders’ and click on it.</li>
                                        <li>Now, select the ‘Shaders Folder’ option which is located in the bottom left portion of the screen.</li>
                                    </ul>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {article === "problems" &&
                <>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">How to solve common problems</h6>
                                </div>
                                <div class="card-body">
                                    <h5>The game won't launch:</h5>
                                    <ul>
                                        <li>The game won't start if your Windows username has a "space" in it like: "John Smith".</li>
                                        <ul>
                                            <li>You can solve this by creating a new user on your PC without spaces in the name and switching to that user before installing</li>
                                        </ul>

                                    </ul>
                                    <h5>The game runs REALLY slow:</h5>
                                    <ul>
                                        <li>If your PC is fairly new (3 years or less) it should be able to run Minecraft without problems. </li>
                                        <ul>
                                            <li>But if you have the 32 bit version of Java installed, that will slow the game to a crawl.</li>
                                            <li>Goto: "Add or remove program" and uninstall all versions of Java</li>
                                            <li>Reinstall Java - but make sure it's the 64 bit version - it has "x64" in the filename.<br></br>
                                             For example: <code>jre-8u271-windows-x64.exe</code></li>
                                        </ul>

                                    </ul>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default Documentation;