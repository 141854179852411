import React from 'react';

function ShowServer({ statusIcon, actionIcon, games }) {
    return (
        <>
            <div className="row">
                <div className="col-xl-11 col-md-12 col-sm-12 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Serverlist
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800 ">
                                        {statusIcon} world.protek.dk
                                    </div>
                                </div>
                                <div className="col-auto">
                                    {actionIcon}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* Page Heading */}
                <div className="col-xl-11 col-md-12 col-sm-12 mb-4">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Minecraft Worlds</h6>
                        </div>
                        <div className="card-body">
                            {games.length > 0 ?
                                <>
                                    {games}
                                </>
                                : <>No games running</>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowServer;