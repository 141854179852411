import React, { useState, useEffect, useCallback } from 'react';
import { GoogleLogout } from 'react-google-login';
import Documentation from './Documentation';
import ShowServer from './ShowServer';

import axios from 'axios';

function MainPage({ auth, clientId, setAuth }) {
    const stateType = { "running": "running", "stopping": "stopping", "pending": "pending", "stopped": "stopped" };

    const pageType = { "server": "server", "join": "join", "install": "install", "pretty": "pretty", "problems": "problems" };

    const serverOffstate = {
        playersOnline: 0,
        statusIcon: <i className="fas fa-stop-circle text-danger align-middle" ></i>,
        actionIcon: <button className="btn btn-primary" onClick={startServer}><i className='fas fa-play fa-2x'></i></button>,
        games: [],
        server: stateType.stopped
    }

    const serverPendingstate = {
        playersOnline: 0,
        statusIcon: <i className="fas fa-question-circle text-primary align-middle"></i>,
        actionIcon: <div className="spinner-border text-dark" role="status"><span className="sr-only">Loading...</span></div>,
        games: [],
        server: stateType.pending
    }

    const [serverStatus, setServerstatus] = useState(serverPendingstate);
    const [pageState, setPageState] = useState(pageType.server);
    const [isKeepAlive, setCallKeepAlive] = useState(true)

    const sendKeepAliveEveryXSeconds = 45;
    const baseURL = "https://rest.protek.dk/mc";

    useEffect(() => {
        // modify body-class after each reload
        document.body.className = "page-top";

        // Call Bootstrap template behavoiur scripts that have not been ported to React
        window.clientSetup();
        
        // Grant Server access to the current user 
        grantAccess(auth.name + " - " + auth.email)

        // start the keep-alive loop
        // keepaliveLoop(serverStatus);
        keepAliveLoop()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLogout = () => {
        setAuth(null);
    };

    const updateServerStatus = (payload) => {
        let newState = {};
        if (payload === false || payload.server === stateType.stopped) {
            setServerstatus(serverOffstate);
            return;
        }

        if (payload.server === stateType.pending || payload.server === stateType.stopping) {
            setServerstatus(serverPendingstate);
            return;
        }

        let gamePending = false;
        if (payload.server === stateType.running) {

            newState = {
                playersOnline: 0,
                statusIcon: <i className="fas fa-check-circle text-success align-middle"></i>,
                actionIcon: <button className="btn btn-danger" onClick={stopServer}><i className='fas fa-stop fa-2x'></i></button>,
                games: [],
                server: stateType.running
            }

            if (payload && payload.minecraftserver && payload.minecraftserver !== "offline") {
                for (let i = 0; i < payload.minecraftserver.length; i++) {
                    const game = payload.minecraftserver[i];
                    if (game.status.description) {
                        const item = game.status;
                        let players = 0;
                        if (item.players) {
                            players = Number(item.players.online);
                        }
                        let playerPercent = players * 5;
                        newState.playersOnline += players;
                        let forge = "";
                        let modsPopupButton = "";
                        let modsList = "";
                        if (item.forgeData){
                            forge = "-forge";
                            for(let i=0; i<item.forgeData.mods.length;i++){
                                modsList += item.forgeData.mods[i].modId + " " + item.forgeData.mods[i].modmarker + "\n";
                            }
                            modsPopupButton = <button type="button" class="btn btn-sm btn-info" data-toggle="popover" title="Server mods" data-content={modsList}><i class="fas fa-dice-d6"></i></button>;
                        }
                        newState.games.push(
                            <React.Fragment key={"worldOnline" + i}>
                                <h5 className="font-weight-bold">{item.description.text} (v{item.version.name}{forge}) &nbsp;&nbsp;{modsPopupButton}<span className="float-right">{players} <i className="fas fa-user"></i></span></h5>
                                <div><small>Server Address:</small> world.protek.dk:{game.port}</div>
                                <div className="progress mb-4">
                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: playerPercent + "%" }} aria-valuenow={Number(players)} aria-valuemin="0" aria-valuemax="20"></div>
                                </div>
                            </React.Fragment>
                        );
                    } else {
                        gamePending = true;
                        newState.games.push(
                            <React.Fragment key={"worldOffline" + i}>
                                <h5 className="font-weight-bold">Loading... <span className="float-right"><div className="spinner-border text-dark" role="status"><span className="sr-only">Loading...</span></div></span></h5>
                                <div><small>Server Address:</small> world.protek.dk:{game.port}</div>
                                <br />
                            </React.Fragment>
                        );
                    }
                }
            }
        } else {
            newState = serverOffstate;
        }
        if (gamePending) {
            newState.pending = true;
            setTimeout(() => { setStatus(null, null, true) }, 2600);
        }
        setServerstatus(newState);
    }

    function grantAccess(description) {
        axios.get(baseURL + `/server_grantaccess/` + description);
    }

    function setStatus(fromStatus, expectedStatus, forceStatus) {
        axios.get(baseURL + `/server_status`)
            .then(res => {
                if (!forceStatus && ((res.data.server !== expectedStatus && expectedStatus !== undefined)
                    || (res.data.server === "pending" || res.data.server === "stopping")
                    || (res.data.minecraftserver[0] && res.data.minecraftserver[0] === "offline"))) setTimeout(() => { setStatus({ from: fromStatus, to: expectedStatus }); }, 2600);
                if (res.data.server !== fromStatus || forceStatus) updateServerStatus(res.data);
            })
    }

    function startServer() {
        updateServerStatus({ server: stateType.pending });
        axios.get(baseURL + `/server_start`).then(() => {
            // Wait a bit before asking for a new status - if we ask too soon the has not reached "pending"-state
            setTimeout(() => { setStatus({ from: stateType.stopped, to: stateType.running }) }, 3000);
        });
    }

    function stopServer() {
        updateServerStatus({ server: stateType.stopping });
        axios.get(baseURL + `/server_forcestop`).then(() => {
            // Wait a bit before asking for a new status - if we ask too soon the has not reached "stopping"-state
            setTimeout(() => { setStatus({ from: stateType.running, to: stateType.stopped }) }, 5000);
        });
    }


    useEffect(() => {
        window.$('[data-toggle="popover"]').popover();
        if (isKeepAlive) {
            const activateKeepAlive = () => {
                if (serverStatus.server === stateType.running) {
                    axios.get(baseURL + `/server_keepalive`);
                    console.log("Keepalive signal sent!")
                }
                setStatus(null, null, true);
            };
            activateKeepAlive();
            setCallKeepAlive(false)
        }
    }, [isKeepAlive, serverStatus.server, setStatus, stateType.running])

    function keepAliveLoop() {
        setCallKeepAlive(true);
        setTimeout(keepAliveLoop, sendKeepAliveEveryXSeconds * 1000);
    }

    return (
        <>
            <div id="wrapper">
                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <i className="fas fa-cogs"></i>
                        </div>
                        <div className="sidebar-brand-text mx-3">Minecraft Admin</div>
                    </a>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0" />

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => { setPageState(pageType.server) }} href="#dashboard">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>Dashboard</span></a>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider" />

                    {/* Heading */}
                    <div className="sidebar-heading">
                        Documentation
                    </div>

                    <li className="nav-item">
                        <a className="nav-link" onClick={() => { setPageState(pageType.install) }} href="#howtoinstall">
                            <i className="fas fa-fw fa-box-open"></i>
                            <span>How to install</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => { setPageState(pageType.join) }} href="#howtojoin">
                            <i className="fas fa-fw fa-bolt"></i>
                            <span>How to join</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => { setPageState(pageType.pretty) }} href="#makepretty">
                            <i className="fas fa-fw fa-eye"></i>
                            <span>Make the game prettier</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => { setPageState(pageType.problems) }} href="#solveproblems">
                            <i className="fas fa-fw fa-bug"></i>
                            <span>Solve common problems</span>
                        </a>
                    </li>
                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block" />

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                    {/* Main Content */}
                    <div id="content">

                        {/* Topbar */}
                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                            {/* Sidebar Toggle (Topbar) */}
                            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                                <i className="fa fa-bars"></i>
                            </button>


                            <div className="h5 text-gray-800">
                                Welcome, {auth.givenName}
                            </div>

                            {/* Topbar Navbar */}
                            <ul className="navbar-nav ml-auto">

                                {/* Nav Item - Search Dropdown (Visible Only XS) */}
                                <li className="nav-item dropdown no-arrow d-sm-none">
                                </li>

                                {/* Nav Item - Messages */}
                                <li className="nav-item dropdown no-arrow mx-1">
                                    <span className="nav-link dropdown-toggle">
                                        <i className="fas fa-user fa-2x"></i>
                                        {/* Counter - Messages */}
                                        <span style={{ fontSize: "1rem" }} className="badge badge-danger badge-counter">{serverStatus.playersOnline}</span>
                                    </span>
                                </li>

                                <div className="topbar-divider d-none d-sm-block"></div>

                                {/* Nav Item - User Information */}
                                <li className="nav-item dropdown no-arrow">
                                    <a className="nav-link dropdown-toggle" href="#u" id="userDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{auth.name}</span>
                                        <img className="img-profile rounded-circle" alt="It's you!"
                                            src={auth.imageUrl} />
                                    </a>
                                    {/* Dropdown - User Information */}
                                    <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                                        <a className="dropdown-item" href="#u" data-toggle="modal" data-target="#logoutModal">
                                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Logout
                                        </a>
                                    </div>
                                </li>

                            </ul>

                        </nav>
                        {/* End of Topbar */}

                        {/* Begin Page Content */}
                        <div className="container-fluid">
                            {pageState === pageType.server &&
                                <ShowServer statusIcon={serverStatus.statusIcon} actionIcon={serverStatus.actionIcon} games={serverStatus.games} />
                            }
                            {pageState !== pageType.server &&
                                <Documentation article={pageState} />
                            }
                        </div>
                        {/* /.container-fluid */}

                    </div>
                    {/* End of Main Content */}

                    {/* Footer */}
                    <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                                <span>Made by <a tagert="protek" href="https://protek.dk">protek.dk</a></span>
                            </div>
                        </div>
                    </footer>
                    {/* End of Footer */}

                </div>
                {/* End of Content Wrapper */}

            </div>
            {/* End of Page Wrapper */}

            {/* Scroll to Top Button*/}
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a>

            {/* Logout Modal*/}
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {(serverStatus.games.length > 0 && serverStatus.playersOnline === 0) ?
                            <>
                                <div className="modal-body">
                                    The server is still running - but with no players...
                                    <br /><br />
                                    So I'm guessing what you ment to do was "Stop server & Logout"

                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <GoogleLogout
                                        clientId={clientId}
                                        buttonText="Logout"
                                        onLogoutSuccess={onLogout}
                                        render={renderProps => (
                                            <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={renderProps.onClick}>
                                                No, just "Logout"
                                            </button>
                                        )}
                                    ></GoogleLogout>
                                    <GoogleLogout
                                        clientId={clientId}
                                        buttonText="Logout"
                                        onLogoutSuccess={onLogout}
                                        render={renderProps => (
                                            <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={() => { stopServer(); renderProps.onClick() }}>
                                                Yes, stop server and Logout
                                            </button>
                                        )}
                                    ></GoogleLogout>
                                </div>
                            </> :
                            <>
                                <div className="modal-body">
                                    Hope to see you soon!
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                                    <GoogleLogout
                                        clientId={clientId}
                                        buttonText="Logout"
                                        onLogoutSuccess={onLogout}
                                        render={renderProps => (
                                            <button className="btn btn-primary" type="button" data-dismiss="modal" onClick={renderProps.onClick}>
                                                Logout
                                            </button>
                                        )}
                                    ></GoogleLogout>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainPage;
