import React, { useState, useEffect } from 'react';

import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from '../utils/refreshToken';

function Login({ clientId, setAuth }) {
  useEffect(() => {
    // change the bg-color of the body after every page reload
    document.body.className = "bg-gradient-primary";
  }, []);

  const onSuccess = (res) => {
    setAuth(res.profileObj);
    refreshTokenSetup(res);
  };

  const [loading, setLoading] = useState(true);

  const onAutoLoadFinished = (res) => {
    setLoading(false);
  };

  const onFailure = (res) => {
    alert(
      `Failed to login.`
    );
  };

  return (
    <>
      {loading && <>
        <div style={{ height: "100vh", textAlign: "center" }}>
          <div style={{ height: "40vh" }}></div >
          <div className="spinner-border text-dark" style={{ width: "5rem", height: "5rem"}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <GoogleLogin
          clientId={clientId}
          onSuccess={onSuccess}
          onAutoLoadFinished={onAutoLoadFinished}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
          render={renderProps => (
            <></>
          )}
          isSignedIn={true}
        />
      </>}

      {!loading &&
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <div className="h5 text-gray-900 mb-1">Stephan and Mathilde's</div>
                          <h1 className="h2 text-gray-900 mb-4">Minecraft World</h1>
                        </div>
                        <div className="text-center">
                          Please sign-in using your Google Account.
                      <br />
                          <br />
                        </div>
                        <form className="user">
                          <GoogleLogin
                            clientId={clientId}
                            buttonText="Login to Stephan's Minecraft Server"
                            onSuccess={onSuccess}
                            onRequest={onAutoLoadFinished}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            className="btn btn-google btn-user btn-block"
                            style={{ marginTop: '100px' }}
                            render={renderProps => (
                              <button onClick={renderProps.onClick} className="btn btn-google btn-user btn-block">
                                <i className="fab fa-google fa-fw"></i> Login with Google
                              </button>
                            )}
                            isSignedIn={true}
                          />

                        </form>
                        <hr />
                        <div className="text-center">
                          <b>Please note!</b> <br /><br />
                          Your IP and e-mail adress will be recorded in AWS (eu-north-1) to manage player access.
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )


}


export default Login;
